import './components/glider.js'; // babel will convert this to require()

/* 
/   Gallery XHR AJAX load image
*/
const galleryXHR = () => {

    let elTarget = event.target
    let postid = event.target.id;
    let postclass = event.target.classList.contains('gallery-image');

    if (postclass) {
        const xhr = new XMLHttpRequest(); 
        xhr.open('POST', '/wp-admin/admin-ajax.php', true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.onload = function() {
            if (xhr.status === 200) {
                console.log('Something went wrong. ' + xhr.responseText);

                const url = xhr.responseText;
                const p = document.getElementById('gallery-xhr');
                const image = '<picture data-picasso="true" loading="lazy">' +
                                '<source loading="lazy" onerror="fallback(parentNode)" type="image/webp" class="gallery-image" srcset="' + url + '">' +
                                '<img src="' + url + '" alt="gallery image" loading="lazy">' +
                            '</picture>';

                p.innerHTML = image;
            }
            else if (xhr.status !== 200) { 
                console.log('Request failed.  Returned status of ' + xhr.status);     
            }
        };
        xhr.send("action=mrs_gallery&postid=" + postid);
    }
}

/* 
/   Glider Calls
*/
const gliderSlider = () => {
    //  we need to check that the element we are setting the glider to exists as we don't want console errors when it returns undefined!
    let gallery_slider = document.getElementById('gallery-glider');
    if (typeof(gallery_slider) != 'undefined' && gallery_slider != null) 
    {
        new Glider(document.querySelector('#gallery-glider .wp-block-gallery'), {
            // Mobile-first defaults 
            slidesToShow: 2,
            slidesToScroll: 2,
            draggable: false,
            arrows: {
                prev: '.glider-prev.gallery',
                next: '.glider-next.gallery'
            },
            responsive: [
                {
                  breakpoint: 1920,
                  settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1, 
                  }
                },                 
                {
                  breakpoint: 1199,
                  settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1, 
                  }
                },                 
                {
                  breakpoint: 767,
                  settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1, 
                  }
                },              
                {
                  breakpoint: 550,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1, 
                  }
                }
            ]
        });
    }
}

/* 
/   Yoast FAQs
*/
const yoastFaqs = () => {

    let acc = document.getElementsByClassName("schema-faq-question");
    [].forEach.call(acc, function (el) {
        el.addEventListener("click", function() {
            el.classList.toggle("active");
        });
    });
}

/* 
/   Window Load EVent
*/
const loadEvent = ()  => {
    gliderSlider();
    yoastFaqs();
}
window.addEventListener('load', loadEvent);   

/*
/   Document Click Event
*/
const clickEvent = (event) => {
  galleryXHR(); 
} 
document.addEventListener('click', clickEvent);  
